.multi-range-slider * {
    box-sizing: border-box;
    padding: 0px;
    margin: 0px;
}

.multi-range-slider {
    display: flex;
    position: relative;
    border: solid 1px gray;
    border-radius: 10px;
    padding: 20px 10px;
    box-shadow: 1px 1px 4px black;
    flex-direction: column;
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently supported by Chrome, Edge,*/
}

.multi-range-slider .bar {
    display: flex;
}

.multi-range-slider .bar-left {
    width: 25%;
    background-color: #f0f0f0;
    border-radius: 10px 0px 0px 10px;
    box-shadow: none !important;
    padding: 2px 0px !important;
}

.multi-range-slider .bar-right {
    width: 25%;
    background-color: #f0f0f0;
    border-radius: 0px 10px 10px 0px;
    box-shadow: none !important;
}

.multi-range-slider .bar-inner {
    background-color: lime;
    display: flex;
    flex-grow: 1;
    flex-shrink: 1;
    justify-content: space-between;
    position: relative;
    border: none !important;
    justify-content: space-between;
    box-shadow: none !important;
}

.multi-range-slider .bar-inner-left {
    width: 50%;
}

.multi-range-slider .bar-inner-right {
    width: 50%;
}

.multi-range-slider .thumb {
    background-color: white;
    position: relative;
    z-index: 1;
    cursor: pointer;
}

.multi-range-slider .thumb::before {
    content: '';
    background-color: inherit;
    position: absolute;
    width: 12px !important;
    height: 12px !important;
    border: none !important;
    box-shadow: none !important;
    border-radius: 50%;
    z-index: 1;
    margin: -4px -12px !important;
    cursor: pointer;
}

.multi-range-slider .input-type-range:focus+.thumb::after {
    content: '';
    position: absolute;
    top: -4px;
    left: -7px;
    width: 11px;
    height: 11px;
    z-index: 2;
    border-radius: 50%;
    border: none !important;
    box-shadow: none !important;
}

.multi-range-slider .caption {
    position: absolute;
    bottom: 35px;
    width: 2px;
    height: 2px;
    left: 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: visible;
    display: none;
}

.multi-range-slider .thumb .caption * {
    position: absolute;
    min-width: 30px;
    height: 30px;
    font-size: 75%;
    text-align: center;
    line-height: 30px;
    background-color: blue;
    border-radius: 15px;
    color: white;
    box-shadow: 0px 0px 5px black;
    padding: 0px 5px;
    white-space: nowrap;
}

.multi-range-slider .thumb:active .caption {
    display: flex;
}

.multi-range-slider .input-type-range:focus+.thumb .caption {
    display: flex;
}

.multi-range-slider .input-type-range {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    opacity: 0;
    pointer-events: none;
}

.multi-range-slider .ruler {
    margin: 10px 0px -5px 0px;
    display: flex;
    /* display: none; */
    overflow: hidden;
}

.multi-range-slider .ruler .ruler-rule {
    border-left: solid 1px;
    border-bottom: solid 1px;
    display: flex;
    flex-grow: 1;
    flex-shrink: 1;
    padding: 5px 0px;
}

.multi-range-slider .ruler .ruler-rule:last-child {
    border-right: solid 1px;
}

.multi-range-slider .ruler .ruler-sub-rule {
    border-left: solid 1px;
    /* border-bottom: solid 1px; */
    display: flex;
    flex-grow: 1;
    flex-shrink: 1;
    padding: 3px 0px;
    bottom: 0px;
    margin-bottom: -5px;
}

.multi-range-slider .ruler .ruler-sub-rule:first-child {
    border-left: none;
}

.multi-range-slider .labels {
    display: flex;
    justify-content: space-between;
    padding: 0px;
    margin-top: 10px;
    margin-bottom: -20px;
    /* display: none; */
}

.multi-range-slider .label {
    font-size: 80%;
    display: flex;
    width: 1px;
    justify-content: center;
}

.multi-range-slider .label:first-child {
    justify-content: start;
}

.multi-range-slider .label:last-child {
    justify-content: end;
}

.multi-range-slider.zero-ranage-margin .thumb-left {
    right: 12px;
}

.multi-range-slider.zero-ranage-margin .thumb-right {
    left: 8px;
}