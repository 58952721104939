@import url(./multirangeslider.css);

@font-face {
    font-family: 'Amazon Ember';
    font-weight: 200;
    src: url(https://m.media-amazon.com/images/S/sash/Dv1WQ5DdeMS5qP7.woff2) format("woff2"), url(https://m.media-amazon.com/images/S/sash/e0LnMbFWJC-TMQz.woff) format("woff")
}

@font-face {
    font-family: 'Amazon Ember';
    font-weight: 200;
    font-style: italic;
    src: url(https://m.media-amazon.com/images/S/sash/ozb5-CLHQWI6Soc.woff2) format("woff2"), url(https://m.media-amazon.com/images/S/sash/twzZHebXjCHBb6v.woff) format("woff")
}

@font-face {
    font-family: 'Amazon Ember';
    src: url(https://m.media-amazon.com/images/S/sash/pDxWAF1pBB0dzGB.woff2) format("woff2"), url(https://m.media-amazon.com/images/S/sash/kfKKBuoqcD$AUKL.woff) format("woff")
}

@font-face {
    font-family: 'Amazon Ember';
    font-style: italic;
    src: url(https://m.media-amazon.com/images/S/sash/WOnTLzkiaEccV7F.woff2) format("woff2"), url(https://m.media-amazon.com/images/S/sash/JJsp0ZvgpfwzJM6.woff) format("woff")
}

@font-face {
    font-family: 'Amazon Ember';
    font-weight: 500;
    src: url(https://m.media-amazon.com/images/S/sash/VjTR4RqBzY0mUYx.woff2) format("woff2"), url(https://m.media-amazon.com/images/S/sash/XIvhNCZAsrT80Wz.woff) format("woff")
}

@font-face {
    font-family: 'Amazon Ember';
    font-weight: 500;
    font-style: italic;
    src: url(https://m.media-amazon.com/images/S/sash/KwhNPG8Jz-Vz2X7.woff2) format("woff2"), url(https://m.media-amazon.com/images/S/sash/YAEPfuhs1l-argd.woff) format("woff")
}

@font-face {
    font-family: 'Amazon Ember';
    font-weight: 700;
    src: url(https://m.media-amazon.com/images/S/sash/KFPk-9IF4FqAqY-.woff2) format("woff2"), url(https://m.media-amazon.com/images/S/sash/mzVbGSgvdBfRLX9.woff) format("woff")
}

@font-face {
    font-family: 'Amazon Ember';
    font-style: italic;
    font-weight: 700;
    src: url(https://m.media-amazon.com/images/S/sash/2SazJx$EeTHfhMN.woff2) format("woff2"), url(https://m.media-amazon.com/images/S/sash/CjbL$jCCegyfqZ7.woff) format("woff")
}

body {
    font-family: 'Amazon Ember', sans-serif !important;
}

.w-4 {
    width: 4% !important;
}

.w-10 {
    width: 10% !important;
}

.w-15 {
    width: 13% !important;
}

.w-16 {
    width: 16% !important;
}

.w-18 {
    width: 18% !important;
}

.w-96 {
    width: 96% !important;
}

.w-20 {
    width: 20% !important;
}

.w-28 {
    width: 28% !important;
}

.w-30 {
    width: 30% !important;
}

.w-35 {
    width: 35% !important;
}

.w-40 {
    width: 40% !important;
}

.w-55 {
    width: 55% !important;
}

.w-60 {
    width: 60% !important;
}

.fs-10 {
    font-size: 10px !important;
    font-weight: normal;
    font-family: "Amazon Ember", Helvetica, sans-serif;
}

.fs-11 {
    font-size: 11px !important;
    font-weight: normal;
    font-family: "Amazon Ember", Helvetica, sans-serif;
}

.fs-12 {
    font-size: 12px !important;
    font-weight: normal;
    font-family: "Amazon Ember", Helvetica, sans-serif;
    line-height: 0.9rem;
}

.fs-13 {
    font-size: 13px !important;
    font-weight: normal;
    font-family: "Amazon Ember", Helvetica, sans-serif;
}

.fs-14 {
    font-size: 14px !important;
    font-weight: normal;
    font-family: "Amazon Ember", Helvetica, sans-serif;
}

.fs-15 {
    font-size: 15px !important;
    font-weight: normal;
    font-family: "Amazon Ember", Helvetica, sans-serif;
}

.fs-21 {
    font-size: 21px !important;
    font-weight: normal;
    font-family: "Amazon Ember", Helvetica, sans-serif;
}

.fs-22 {
    font-size: 22px !important;
    font-weight: normal;
    font-family: "Amazon Ember", Helvetica, sans-serif;
}

.fs-23 {
    font-size: 23px !important;
    font-weight: normal;
    font-family: "Amazon Ember", Helvetica, sans-serif;
}

.fs-24 {
    font-size: 24px !important;
    font-weight: normal;
    font-family: "Amazon Ember", Helvetica, sans-serif;
}

.fs-25 {
    font-size: 25px !important;
    font-weight: normal;
    font-family: "Amazon Ember", Helvetica, sans-serif;
}

.fs-28 {
    font-size: 28px !important;
    font-weight: normal;
    font-family: "Amazon Ember", Helvetica, sans-serif;
}

.fs-17 {
    font-size: 17px !important;
}

.fs-18 {
    font-size: 18px !important;
    font-weight: normal;
    font-family: "Amazon Ember", Helvetica, sans-serif;
}

.fs-19 {
    font-size: 19px !important;
    font-weight: normal;
    font-family: "Amazon Ember", Helvetica, sans-serif;
}

.fw-400 {
    font-weight: 400 !important;
}

.fw-700 {
    font-weight: 700 !important;
}

.fw-200 {
    font-weight: 200 !important;
}

.fw-500 {
    font-weight: 500 !important;
}

.fw-600 {
    font-weight: 600 !important;
}

.advise-login-container {
    background-color: #0066c0 !important;
}

.sponsredads-text-color {
    color: rgb(206, 211, 220) !important;
}

.campaign-navbar {
    border-right: 0px !important;
}

a.navbar-brand {
    border-right: 1px solid #000;
}

.top_menu {
    border-bottom: 3px solid #FF610C;
    height: 60px;
}

.login-form {
    border: 1px solid lightgrey;
    border-radius: 10px;
    box-shadow: 1px 1px 3px #232F3F;
}

.custom-bg-btn {
    background-color: #232F3F;
    border-radius: 10px;
}

.custom-text-color {
    color: #232F3F;
    font-weight: 600;
}

.custom-background-color {
    background-color: #232F3F;
}

.custom-cancel-btn {
    background-color: #EEF5FF;
}

.offcanvas-view {
    /* z-index: 12345 !important; */
    z-index: 1183 !important;
}

.side-menu-offcanvas-view {
    position: absolute;
    /* z-index: 12345 !important; */
    z-index: 1183 !important;
}

.sidemenu-item .active {
    background-color: #232F3F;
    color: white !important;
}

/* li a:hover {
    background-color: lightgrey;
    color: white !important;
} */

.breadcum-text-color {
    color: #232F3F !important;
    font-weight: bold;
}

.sidebar {
    height: calc(100vh - 72px);
    overflow-y: auto;
    border-right: 1px solid #232F3F33;
}

.menu-offcanvas {
    width: 300px !important
}

.off-canvas-header-position {
    position: absolute;
    top: 65px;
    left: 75px;
}

.off-canvas-menu-header-position {
    position: absolute;
    top: 57px;
    left: 50px !important;
}

.off-canvas-close-button-position {
    position: absolute;
    left: 190px;
}

.list-group-item {
    color: rgb(35, 47, 63);
    text-decoration: none;
    background-color: rgba(35, 47, 63, 0.1);
    background-color: rgba(35, 47, 63, 0.1);
}

.list-group-item.active {
    background-color: rgba(35, 47, 63, 0.1) !important;
    color: black !important;
    border-color: transparent !important;
}

.list-group-item:hover {
    background-image: url('./../images/svg_icons/home.svg') !important;
    background-repeat: repeat !important;
}

.side-submenu-list {
    width: 109% !important;
    text-align: start !important;
}

.side-submenu-list a:hover i:hover {
    background-image: url('./../images/svg_icons/home.svg') !important;
    background-repeat: repeat !important;
    color: black !important;
}

.list-home-icon {
    color: transparent !important;
}

.list-group-item:hover .list-home-icon:hover {
    color: black !important;
}

.sidebar li {
    list-style-type: none;
    border-bottom: 0px solid #232F3F33;
}

.sidebar li:hover {
    background-color: lightgrey;
    color: white;
}

.sidebar li.active {
    background-color: #232F3F;
    color: white;
}

.nav-list li a.active {
    background-color: #232F3F;
    color: white;
}

.sidebar .nav-list a.active li {
    border-top: 1px solid rgb(230, 233, 237);
    border-bottom: 1px solid rgb(230, 233, 237);
    padding: 20px 15px !important;
}

.nav-link.active {
    background-color: #232F3F;
    color: white;
}

.nav-item:hover {
    background-color: white !important;
    color: black !important;
}

.content-scroll {
    height: calc(100vh - 60px);
    overflow-y: auto;
}

.table_new_ui th {
    border: none;
}

.table_new_ui th {
    background: rgb(247, 247, 247) !important;
}

.table-header {
    background-color: #232F3F !important;
    color: white !important;
}

.table_new_ui th,
.table_new_ui td {
    font-size: 12px;
    color: #333333;
    font-weight: 400;
}

.breadcrumb>li+li:before {
    content: "" !important;
    text-decoration: none !important;
}

.breadcrumb {
    padding: 10px 16px;
    font-size: 14px !important;
    color: #aaa !important;
    border-radius: 5px !important
}

.breadcrumb li {
    padding: 0px 3px !important;
}

.page-link {
    color: #232F3F !important;
}

.page-link.active {
    background-color: #232F3F !important;
    color: white !important;
}

.page-link:hover {
    background-color: #232F3F !important;
    color: white !important;
}

.active>.page-link,
.page-link.active {
    background-color: #232F3F !important;
    color: white !important;
}

.page-link:focus {
    box-shadow: none !important;
}

.disabled {
    background-color: #EEF5FF !important;
    color: black !important;
}

.search {
    height: 31px !important;
}

.search-position {
    position: relative;
    top: -29px;
    left: 45%;
    padding: 6.5px 5px;
    cursor: pointer;
}

.pagination {
    text-decoration: none !important;
    list-style-type: none;
    --bs-pagination-border-color: #232F3F !important;
}

.page-item.disabled {
    background-color: #EEF5FF !important;
    color: black !important;
    --bs-pagination-disabled-bg: #EEF5FF !important;
    --bs-pagination-disabled-border-color: #232F3F;
    --bs-pagination-disabled-color: black;
}

.page-item:hover {
    color: white !important;
}

.active>.page-link,
.page-link.active {
    border-color: none !important;
}

.modal-show {
    display: block !important;
}

.modal-hide {
    display: none !important;
}

.modal-bg {
    background-color: #079ea2 !important;
    border-radius: 10px !important;
    border: 0px !important;
}

.modal-header {
    /* border-bottom: none !important; */
}

.modal-footer {
    /* border-top: none !important; */
}

.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent !important;
    backdrop-filter: blur(8px);
    z-index: 1055;
}

.modal-backdrop.show {
    opacity: 1 !important;
}

.country-header-background {
    /* background-color: rgb(92, 104, 124) !important; */
    background-color: #2f343c87 !important;
    padding: 0px 3px;
}

.custom-button-background {
    background: rgb(217, 222, 228) !important;
}

.custom-button-background:hover {
    background: rgb(206, 211, 220);
    color: rgb(35, 47, 63);
    box-shadow: rgba(35, 47, 63, 0.5) 0px 1px 2px;
}

.card-description {
    height: 60px;
    overflow-y: hidden;
}

.notification-beside-text {
    background-color: #354152 !important;
}

.notification-beside-text:hover {
    background: #4c5567 !important;
}

.custom-button-background-color {
    background: rgb(0, 97, 169) !important;
}

.custom-explore-text-color {
    color: #0073C7 !important;
}

.custom-explore-text-color-two {
    color: rgb(0, 115, 199) !important;
}

.dashboard-icon-style {
    width: 40px !important;
    height: 40px !important;
}

.logout-hover:hover {
    background-color: black !important;
}

.dashboard-advice-beside-icon {
    width: 20px !important;
    height: 20px !important;
    color: orangered !important;
}

.dashboard-home-icon {
    display: inline-block !important;
    width: 20px !important;
    height: 25px !important;
    color: white !important;
}

.dashbord-home:hover {
    color: white !important;
}

.dashboard-home-icon:hover {
    color: white !important;
}

.accordion-button:not(.collapsed) {
    background-color: transparent !important;
}

.accordion-body {
    padding: 5px 0px !important;
}

.cursor-pointer {
    cursor: pointer !important;
}

.cursor-pointer-not-allowed {
    cursor: not-allowed !important;
    background: rgb(230, 233, 237) !important;
    box-shadow: none !important;
}

.enterlist-button-text-color {
    color: #7a7880 !important;
}

.carousel-control-next-icon {
    width: 20px !important;
    cursor: pointer;
}

.carousel-control-prev-icon {
    width: 20px !important;
    cursor: pointer;
}

.modal-prev-icon {
    width: 20px !important;
    color: black !important;
    background-color: black !important;
    cursor: pointer;
}

.modal-next-icon {
    width: 20px !important;
    color: black !important;
    background-color: black !important;
    cursor: pointer;
}

.preview-icon-position {
    margin-right: -10px;
    cursor: pointer;
}

.feedback-button-position {
    position: fixed;
    left: auto;
    right: 17px;
    bottom: 0px;
    transform-origin: right bottom;
    transform: rotate(270deg) translateX(100%);
    border-radius: 16px 16px 0px 0px !important;
    box-shadow: rgba(35, 47, 63, 0.3) -1px 0px 1px;
}

.feedback-button {
    bottom: 6rem;
    z-index: 2;
}

.feedback-modal-body-background {
    background: rgb(242, 244, 246);
}

.feedback-modal-position {
    position: relative;
    width: 430px !important;
    left: 29%;
}

.emoji-style {
    display: inline-block;
    height: 30px !important;
    width: 30px !important;
}

.btn-close {
    height: 1em !important;
}

.profile-dropdown {
    width: 250px;
}

.help-dropdown {
    width: 100px;
}

.notification-dropdown {
    width: 450px;
}

.help-dropdown .dropdown-item {
    padding: 10px 10px;
    text-align: start;
}

.notification-dropdown[data-bs-popper] {
    top: 165% !important;
    left: -310px !important;
    margin-top: var(--bs-dropdown-spacer) !important;
}

.profile-dropdown[data-bs-popper] {
    top: 165% !important;
    left: -210px !important;
    margin-top: var(--bs-dropdown-spacer) !important;
}

.help-dropdown[data-bs-popper] {
    top: 165% !important;
    left: -70px !important;
    margin-top: var(--bs-dropdown-spacer) !important;
}

.campaign-create-help-dropdown[data-bs-popper] {
    top: 165% !important;
    left: -112px !important;
    margin-top: var(--bs-dropdown-spacer) !important;
}

.dropdown-item:hover {
    color: black !important;
}

.logout:hover {
    background-color: transparent;
    color: #0073C7 !important;
    text-decoration: underline !important;
}

.custom-dropdown-listitem-color {
    color: #35404f !important;
}

.dropdown-menu {
    --bs-dropdown-link-active-bg: rgb(242, 244, 246) !important;
}

.languages-text {
    color: rgb(111, 119, 130) !important;
}

.languages-globe-icon {
    height: 10px;
    width: 10px;
}

.custom-drop-down {
    line-height: 1.465;
    font-weight: normal;
    font-family: "Amazon Ember", Helvetica, sans-serif;
    padding: 3px 0px;
    white-space: nowrap;
    border-radius: 999px;
    cursor: pointer;
    text-align: center;
    vertical-align: middle;
    border-width: 1px;
    border-style: solid;
    user-select: auto;
    /* background: rgb(217, 222, 228) !important; */
    background-color: rgb(206, 211, 220) !important;
    border-color: transparent;
    color: black !important;
    box-shadow: rgba(35, 47, 63, 0.3) 0px 1px 1px !important;
    padding-inline: 11px;
    font-size: 11px !important;
}

.custom-drop-down:hover {
    color: rgb(35, 47, 63) !important;
}

.profile {
    position: absolute;
    left: 0px;
}

.profile::after {
    content: " ";
    position: absolute;
    transform: rotate(45deg);
    width: 10px;
    height: 10px;
    background-color: rgb(255, 255, 255) !important;
}

.badge-background {
    background-color: rgb(1, 132, 133) !important;
}

.notification-card-bg {
    background-color: #ebf5ff !important;
}

.request-call-button {
    line-height: 1.465;
    padding: 5px 0px;
    white-space: nowrap;
    border-radius: 999px;
    cursor: pointer;
    text-align: center;
    vertical-align: middle;
    border-width: 1px;
    border-style: solid;
    user-select: auto;
    background: rgb(0, 115, 199) !important;
    border-color: transparent;
    box-shadow: rgba(35, 47, 63, 0.3) 0px 1px 1px !important;
    padding-inline: 14px;
}

.request-call-button:hover {
    background: rgb(0, 97, 169) !important;
    box-shadow: rgba(35, 47, 63, 0.4) 0px 1px 2px !important;
}

.explore-help-modal {
    position: relative;
    width: 330px !important;
    left: 37%;
    top: 30px;
}

.explore-modal-body {
    height: 330px;
    overflow: scroll;
    overflow-x: hidden;
}

.explore-modal-navigation-text-color {
    color: #007185 !important;
}

.explore-modal-navigation-text-color:hover {
    text-decoration: underline;
    color: #0073C7 !important;
}

.preview-image-view {
    height: 50% !important;
    width: 50% !important;
    object-fit: cover;
}

.preview-carousel-indicators {
    margin-bottom: 0rem !important;
}

.normal-carousel-indicators {
    margin-bottom: -1rem !important;
}

.modal-custom {
    background-color: rgba(35, 47, 62, 0.8);
}

.slide-icon {
    height: 6px !important;
    width: 6px !important;
    border-radius: 50% !important;
    background-color: black !important;
}

.carousel-caption {
    position: absolute;
    right: 15%;
    bottom: 0.25rem !important;
    left: 15%;
    padding-top: -1.75rem;
    padding-bottom: 1.25rem;
    color: #fff;
    text-align: center;
}

.question-icon {
    display: inline-block !important;
    height: 20px !important;
    width: 20px !important;
}

.question-icon-two:hover {
    background-color: rgba(35, 47, 63, 0.05) !important;
    border-radius: 5px;
}

.ad-group-settings {
    background-color: #ffffff !important;
}

.input[type="text"],
[type="date"] {
    height: 33px !important;
}

.products-tabs-section {
    height: 500px;
    border-right: 1px solid lightgrey;
}

.tabs-section .nav-link:hover {
    background-color: transparent !important;
    color: black !important;
    border-bottom-color: transparent !important;
}

.tabs-section .nav-link {
    background-color: white !important;
    cursor: pointer;
    /* color:black; */
}

.tabs-section .nav-link.active {
    background-color: white !important;
    cursor: pointer;
    /* color:black; */
}

.nav-underline .nav-link.active,
.nav-underline .show>.nav-link {
    font-weight: 700;
    color: var(--bs-nav-underline-link-active-color);
    border-bottom-color: #0073C7 !important;
}

.campaign-card {
    min-height: 467px !important;
    max-width: 351px !important;
}

.campaign-card-type-height {
    height: 548px !important;
}

.modal-preview {
    width: 720px !important;
    height: 419px !important;
}

.preview-modal-image {
    width: 639px !important;
    height: 352px !important;
    object-fit: contain !important;
}

.feedback-modal-position-three {
    z-index: 500;
    position: fixed;
    display: block;
    transform: translate3d(-30px, 98.6667px, 0px) !important;
}

.smile-image-five {
    width: 35px !important;
    height: 35px !important
}

.add-products-text-color {
    color: rgb(118, 118, 118) !important;
}

.products-card {
    height: 570px !important;
    width: 984px !important;
}

.product-preview-container {
    background-color: #fbfbfb !important;
}

.campaign-creation-bg {
    background-color: #fbfbfb !important;
}

.search-position {
    position: relative;
    top: 30px;
    left: auto !important;
    right: 46%;
}

.search-input-position {
    margin-top: -20px !important;
    margin-bottom: -20px !important;
}

.search-second-position {
    position: relative;
    top: -32px;
    left: auto !important;
    right: -54%;
    background: rgb(206, 211, 220) !important;
    color: black !important;
    padding: 8px;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    cursor: pointer;
}

.wrong-icon-position {
    background-color: transparent !important;
    right: -43% !important;
}

.upload-icon {
    background: rgb(251, 251, 251);
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    border: 2px dashed rgb(221, 221, 221);
    border-radius: 4px;
    padding: 60px 0px;
    color: rgb(73, 85, 102);
    cursor: pointer;
    margin-top: 10px;
    height: 200px;
    box-sizing: border-box;
}

.card-width {
    width: 984px !important;
}

.portfolio-setting-card {
    width: 984px !important;
}

.portfolio-name {
    width: 25%;
}

.targeting-card-container {
    height: 196px !important;
}

.automatic-targeting-card-containe {
    height: 196px !important;
}

.form-check .form-check-input:checked {
    background-color: rgb(0, 115, 199) !important;
    border: 1px solid rgb(0, 115, 199) !important;
    cursor: pointer !important;
}

.form-check-input:hover {
    cursor: pointer !important;
    border-color: rgb(0, 102, 192);
    box-shadow: none;
    background-color: rgb(0, 80, 138);
}

.optional-text-color {
    color: #767676;
}

.down-arrow::after {
    flex-shrink: 0;
    width: var(--bs-accordion-btn-icon-width);
    height: var(--bs-accordion-btn-icon-width);
    margin-left: -65.5% !important;
    content: "";
    background-image: var(--bs-accordion-btn-icon);
    background-repeat: no-repeat;
    background-size: var(--bs-accordion-btn-icon-width);
    transition: var(--bs-accordion-btn-icon-transition);
}

.down-arrow-two::after {
    margin-left: -75.5% !important;
}

.adjust-button::after {
    margin-left: -22% !important;
    background-image: url('./../images/svg_icons/down-arrow.svg') !important;
    /* width:8px !important;
    height:8px !important; */
}

.keyword-target-position {
    position: relative;
    left: 130% !important;
}

.keyword-product-position {
    position: relative;
    left: 70% !important;
}

.accordion {
    --bs-accordion-btn-focus-border-color: transparent !important;
    --bs-accordion-btn-focus-box-shadow: none !important;
}

.campaign-bidding-card-container {}

.percentage-position {
    position: relative;
    top: -28px;
    left: 80%;
}

.learn-more-text {
    color: #007185;
    text-decoration: underline;
}

.remove-all-text {
    color: rgb(118, 118, 118) !important;
    background: transparent !important;
}


.daily-budget {
    margin-top: -26px !important;
}

.get-help-modal {
    /* height: calc(100vh - 72px) !important; */
    width: 400px !important;
    transition: right 0.3s ease-in-out 0s;
    position: fixed;
    z-index: 500 !important;
    top: 35px;
    overflow: hidden;
    padding: 10px;
    right: 400px;
    min-height: 100% !important;
}

.suggested-dropdown-box-shadow {
    box-shadow: rgb(0, 115, 199) 3px 0px 0px inset !important;
}

.keyword-below-text-color {
    color: rgb(92, 104, 124) !important;
}

.keyword-witdh {
    width: 100px;
}

.individual-product-searchinput-position {
    margin-top: -40px !important;
}

.exclude-product-search-input-position {
    margin-top: -22px !important;
}

.exclude-brand-search-input-position {
    margin-top: -22px !important;
}

.product-categories-searchinput-position {
    margin-top: -66px !important;
}

.custom-border-color {
    border-color: rgb(191, 191, 191) !important;
}

.border-bottom .border-top {
    border-color: red !important;
}

.rupee-icon-position {
    position: relative;
    top: 26px;
    left: 5px;
}

.set-default-bid-rupee-position {
    margin-top: -10px !important;
}

.set-bids-targeting-group-container {
    width: 626px !important;
}

.setbid-input-width {
    width: 95px !important;
}

.check-position {
    position: relative;
    right: 35px !important;
    bottom: 2px;
    font-size: 10px;
}

.form-switch .form-check-input {
    background-color: rgb(130, 144, 164) !important;
    /* color: rgb(130, 144, 164) !important; */
    color: white !important;
}

.custom-tooltip-position {
    margin-top: 20px !important;
    font-size: 13px !important;
}

.MuiTooltip-popper[data-popper-placement*="top"] .MuiTooltip-tooltipPlacementTop {
    background-color: rgb(255, 255, 255) !important;
    color: rgb(35, 47, 63) !important;
    padding: 15px 18px !important;
    box-shadow: 1px 1px 10px rgb(118, 118, 118) !important;
    color: black !important;
    max-width: 425px !important;
    max-height: 352px !important;
    font-size: 13px !important;
    border-radius: 10px !important;
}

.MuiTooltip-popper[data-popper-placement*="top"] .MuiTooltip-arrow {
    color: white !important;
}

.MuiTooltip-popper[data-popper-placement*="right"] .MuiTooltip-tooltip {
    background-color: rgb(255, 255, 255) !important;
    color: rgb(35, 47, 63) !important;
    padding: 15px 18px !important;
    box-shadow: 1px 1px 10px rgb(118, 118, 118) !important;
    color: black !important;
    max-width: 425px !important;
    max-height: 352px !important;
    font-size: 13px !important;
    border-radius: 10px !important;
}

.MuiTooltip-popper[data-popper-placement*="right"] .MuiTooltip-arrow {
    color: white !important;
}

.MuiTooltip-popper[data-popper-placement*="left"] .MuiTooltip-tooltip {
    background-color: rgb(255, 255, 255) !important;
    color: rgb(35, 47, 63) !important;
    padding: 15px 18px !important;
    box-shadow: 1px 1px 10px rgb(118, 118, 118) !important;
    color: black !important;
    max-width: 425px !important;
    max-height: 352px !important;
    font-size: 13px !important;
    border-radius: 10px !important;
}

.MuiTooltip-popper[data-popper-placement*="left"] .MuiTooltip-arrow {
    color: white !important;
}

.MuiTooltip-popper[data-popper-placement*="bottom"] .MuiTooltip-tooltip {
    background-color: rgb(255, 255, 255) !important;
    color: rgb(35, 47, 63) !important;
    padding: 15px 18px !important;
    box-shadow: 1px 1px 10px rgb(118, 118, 118) !important;
    color: black !important;
    max-width: 425px !important;
    max-height: 352px !important;
    font-size: 13px !important;
    border-radius: 10px !important;
}

.MuiTooltip-popper[data-popper-placement*="bottom"] .MuiTooltip-arrow {
    color: white !important;
}

.tooltip-wrong-icon {
    position: relative;
    top: -6px;
}

.tooltip-wrong-icon:hover {
    color: black !important;
}

.campaign-creation-form-container {
    max-width: 70rem !important;
}

.product-categories-search-list-header {
    width: 100% !important;
    background: rgb(247, 247, 247) !important;
}

.search-header-list {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.input:focus {
    outline-width: 0px !important;
}

.default-bid-input-width {
    width: 100px !important;
}

.choose-bid-input-width {
    width: 55px !important;
}

.categories-accordion-header {
    background-color: white !important;
}

.categories-accordions-list {
    height: 285px;
    overflow-y: auto !important;
}

.categories-accordion-title-container {
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;
    align-items: center !important;
}

.categories-title-header {
    width: 100% !important;
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root {
    background-color: #fff;
    color: rgba(0, 0, 0, 0.87);
    -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 4px;
    /* box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12); */
    position: relative;
    -webkit-transition: margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    overflow-anchor: none;
    border-radius: 0;
}

.css-15v22id-MuiAccordionDetails-root {
    padding: 0px 0px 0px 25px !important;
}

.add-modal {
    width: 274px !important;
    height: 114px !important;
}

.choose-bid {
    width: 100px !important;
}

/* .suggested-drop-down{
    background-color: white !important;
} */
/* =============Table css start============ */
table {
    table-layout: fixed;
    width: 100%;
}

th {
    background: #f3f3f3;
    font-weight: 500;
}

td,
th {
    vertical-align: top;
    /* border-top: 1px solid #ccc; */
    /* padding: 10px; */
    padding: 10px;
    font-size: 13px;
    border-left: 1px solid #e7e7e7 !important;
    border-bottom: 1px solid #e7e7e7 !important;
    /* width: 200px; */
}

.pb_17 {
    padding-bottom: 17px;
}

.th_menu {
    position: absolute;
    right: 5px;
}

.th_menu:hover {
    background: rgb(217, 222, 228);
    border-radius: 40%;
}

.col_table {
    position: absolute;
    /* z-index: 1; */
    /* left: 0; */
    /* width: 100px; */
}

.col2 {
    position: absolute;
    left: 100px;
    width: 100px;
}

.col3 {
    position: absolute;
    left: 200px;
    width: 100px;
}

.col4 {
    position: absolute;
    left: 300px;
    width: 100px;
}

.outer {
    position: relative;
    border: 1px solid #e7e7e7;
}

.inner {
    overflow-x: scroll;
    overflow-y: visible;
    /* width:500px;  */
    margin-left: 900px; 
    /* 900px; */
}

.inner-portfolio-campaigns {
    overflow-x: scroll;
    overflow-y: visible;
    /* width:500px;  */
    margin-left: 563px;
}

.table_icon {
    height: 12px;
}

/* =============Table css end============ */

/* ================checkbox css start ========== */

/* Customize the label (the container_checkbox) */
.container_checkbox {
    display: block;
    position: relative;
    padding-left: 15px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-top: 5px;
}

/* Hide the browser's default checkbox */
.container_checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 14px;
    width: 14px;
    background-color: #fff;
    border-radius: 3px;
    box-shadow: 0 0 0 1px #979797;
}

/* On mouse-over, add a grey background color */
.container_checkbox:hover input~.checkmark {
    cursor: pointer;
    box-shadow: rgb(0, 102, 192) 0px 0px 2px 1px;
    background-size: cover;
    background-color: rgb(172, 209, 236);
}



/* When the checkbox is checked, add a blue background */
.container_checkbox input:checked~.checkmark {
    /* background-color: #2196F3; */
    background-color: #0074c7;
    box-shadow: 0 0 0 1px #0066c0;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.container_checkbox input:checked~.checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.container_checkbox .checkmark:after {
    left: 5px;
    top: 1px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.checkbox_arrow {
    height: 10px;
    margin-top: 8px;
    margin-left: 8px;
}

/* ================checkbox css end ========== */

.switch {
    display: inline-block;
    position: relative;
}

.toggle-input {
    display: none;
}

.toggle-label {
    display: inline-block;
    width: 60px;
    height: 30px;
    background-color: #ccc;
    border-radius: 15px;
    position: relative;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
}

.on-icon,
.off-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 14px;
    color: #fff;
    padding: 0 10px;
    line-height: 30px;
}

.on-icon {
    left: 5px;
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
}

.off-icon {
    right: 5px;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.toggle-input:checked+.toggle-label {
    background-color: #58a05b;
}

.toggle-input:checked+.toggle-label .on-icon {
    opacity: 0;
}

.toggle-input:checked+.toggle-label .off-icon {
    opacity: 1;
}

.info_icon_color {
    color: rgb(130, 144, 164) !important;
}

.custom-tooltip-th {
    margin-left: 10px;
}

.table_menu {
    position: absolute;
    right: -200px;
    border-radius: 8px;
    box-shadow: rgba(35, 47, 63, 0.2) 0px 4px 8px 0px, rgba(35, 47, 63, 0.3) 0px 0px 2px;
    padding: 10px;
    background: white;
    width: 200px;
}

.MuiButtonBase-root.MuiIconButton-root {
    background: rgb(230, 233, 237) !important;
    margin: 3px;
}

.MuiButtonBase-root.MuiIconButton-root svg {
    height: 18px;
    width: 18px;
}

.MuiTablePagination-displayedRows,
.MuiTablePagination-selectLabel {
    margin-bottom: 0px !important;
}

.table_btm_txt {
    font-size: 13px;
    color: rgb(170, 170, 170);
}

.table_date_box {
    transition: none;
    border: 1px solid #bfbfbf;
    background: #f2f4f6;
    padding: 3px;
}


.badge-new {
    padding: 2px 4px !important;
}

.portfolio-select-dropdown {
    width: 200px !important;
    height: 3px I !important;
    border-color: #000 !important;
}

.css-1xnbq41-MuiAutocomplete-root .MuiOutlinedInput-root.MuiInputBase-sizeSmall {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
    padding-left: 6px;
}

.right-mark-position {
    position: relative;
    right: 28px;
    bottom: 2px;
}

.right-mark-position:hover {
    background-color: rgb(0, 97, 169);
}

.toggle-hieght {
    height: 20px !important;
    width: 31px !important;
}

.campaing-settings-daily-budget {
    width: 110px !important;
}

.newest-first-dropdown {
    min-width: 122.281px !important;
    margin-top: -30px !important;
    margin-left: 0px !important;
    padding: 7px 0px !important;
    max-height: 400px !important;
}

.keyword-targeting-bid-dropdown {
    min-width: 318.281px !important;
}

.keyword-targeting-sorting-dropdown {
    min-width: 90.281px !important;
    margin-top: -30px !important;
}

.dropdown-sub-title-text-color {
    color: rgb(118, 118, 118) !important;
}

.dropdown-toggle::after {
    display: none !important;
}

.show-text-icon {
    color: rgb(92, 104, 124) !important;
    font-weight: bold !important;
}

.show-text-icon:hover {
    color: black !important;
}

.new-dropdown-toggle::after {
    background-image: url('./../images/svg_icons/down-arrow.svg') !important;
    background-repeat: no-repeat !important;
}

.newest-first-dropdown li button:hover {
    background-color: rgb(242, 244, 246) !important;
}

.newest-first-dropdown li .active {
    box-shadow: rgb(0, 115, 199) 3px 0px 0px inset;
}

.out-of-budget-text-color {
    color: #CA5905;
}

.custom-drop-down option:hover {
    background-color: rgb(242, 244, 246) !important;
}

.custom-drop-down .active {
    box-shadow: rgb(0, 115, 199) 3px 0px 0px inset !important;
}

/*nag*/

.accordion {
    margin: 10px 0px;
}

.accordion .MuiAccordionSummary-content {
    margin: 0px;
}

.tooltip-header {
    font-size: 17px;
    line-height: 1.255;
    font-weight: 700;
    font-family: "Amazon Ember", Helvetica, sans-serif;
    padding: 0px;
    color: inherit;
    margin: 0px;
}

.profile-card {
    background-color: #F4F5F7;
    border-radius: 5px;
    transition: .5s !important;
}

.profile-card:hover,
.profile-card:focus {
    background-color: #ffffff;
    /* box-shadow: 1px 1px 8px white !important; */
    box-shadow: 1px 1px 15px #0b0a0a54 !important;
    transform: scale(1.02) !important;
}

.overview-btn {
    /* background-color: #11C27C !important; */
    background-color: #00b06a !important;
    text-align: end !important;
}

.seller-btn {
    border-radius: 15px !important;
    /* color: #FCBA42; */
    /* border: solid 1px #FCBA42 !important; */
    border: solid 1px #0a6bb2 !important;
    color: #0a6bb2;
    margin-left: 1rem;
    height: 21px;
    font-size: 13px;
    font-weight: 700;
    background-color: #F4F5F7;
}

.pull-btn {
    border-radius: 15px !important;
    border: solid 1px #232bfd !important;
    margin-left: 1rem;
    color: #232bfd;
    height: 20px;
    font-size: 12px;
    font-weight: 700;
    background-color: #F4F5F7;
}

.toggle-btn {
    top: 0px;
    right: 0px;
}

.create-campaign-btn {
    background-color: rgb(0, 115, 199) !important;
}

.dot-circle {
    height: 8px;
    width: 8px;
    background-color: #11C27C;
    border-radius: 50%;
    display: inline-block;
}

.india-flag-image {
    height: 25px;
    width: 25px;
    object-fit: contain;
}

.india-text {
    margin-left: -10px !important;
}

.active-check-mark-psotion {
    bottom: 0px !important;
}

.active-check-mark-psotion:hover {
    background-color: transparent !important;
}

.dashboard-side-menu-container {
    background-color: rgb(251, 251, 252) !important;
    /* height:calc(100vh - 40px) !important;
    overflow-y: auto !important; */
}

.dashboard-side-menu-container li {
    list-style-type: none;
    padding: 10px 10px;
    cursor: pointer;
}

.dashboard-side-menu-container li:hover {
    /* background-color: rgb(210, 211, 220) !important; */
    /* background: rgba(35, 47, 63, 0.05); */
    background-color: #232f3f03 !important;
}

.dashboard-side-menu-container .active li {
    /* background: rgba(35, 47, 63, 0.1); */
    background-color: #232f3f0d !important;
    padding: 10px 10px;
}

.targeting-groups-right-mark-position {
    right: 35px !important;
    bottom: 0px !important;
}

.targeting-groups-right-mark-position:hover {
    background-color: transparent !important;
}

.country-india-btn-container {
    height: 40px;
}

.list-group-item.active:hover .list-home-icon {
    color: black !important;
}

.portfolio-dropdown {
    min-width: 150.7812px !important;
    margin-top: -29px !important;
    margin-left: -2px !important;
    padding: 7px 0px !important;
    max-height: 320px !important;
    overflow-y: auto !important;
}

.drop-down-input {
    height: 30px !important;
}

.custom-select {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
}

.choose-your-campaign-header {
    width: 70rem;
}

.filters-background {
    background: #f3f3f3;
}

.filters-dropdown {
    min-width: 127px !important;
}

.columns-dropdown {
    min-width: 160px !important;
}

.filterType-margin-left {
    margin-left: 10px !important;
}

.columns-margin-left {
    margin-left: 60px !important;
}

.date-range-margin-left {
    margin-left: 87px !important
}

.export-margin-left {
    margin-left: -7px !important;
}

.table_checkbox_option {
    z-index: 2;
    width: 100px;
    border-radius: 8px;
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(35, 47, 63, 0.2) 0px 4px 8px 0px, rgba(35, 47, 63, 0.3) 0px 0px 2px 0px;
    z-index: 2;
    padding: 10px 10px 10px 10px;
    position: absolute;
    right: -90px;
    top: -5px;
    right: auto;
    left: 46px;
    top: 10px;
}

.table_checkbox_option div {
    width: 100px;
    padding: 5px;
}

.tb_left_border {
    border-right: 1px solid #e7e7e7 !important;
}

.fw_700 {
    font-weight: 700;
    background-color: #f3f3f3;
}

.menu-tooltip {
    /* background-color:#000 !important; */
}

.header-right-mark {
    right: 32px;
}

.accordion-sub-menu {
    border-right: solid 1px rgb(184, 191, 203);
}

.accordion-sub-menu:hover {
    background-color: rgb(242, 244, 246) !important;
}

.card-left {
    margin-left: 3rem !important;
}

.cards-container {
    width: 70rem !important;
}

.find-a-portfolio {
    /* width:200px !important; */
    width: 35% !important;
}

.card-border-style {
    border-style: dashed !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.inner-without-fixed-columns {
    /* overflow-x: scroll; */
    overflow-y: visible;
}

.ad-groups-table-container {
    padding: 0px 28px !important;
}

.negative-keyword-table-container {
    padding: 0px 16px !important;
}

.budget-rules-table-container {
    padding: 0px 16px !important;
}

.negative-keyword-table-header-bg {
    background-color: rgba(255, 255, 255, 0.8) !important;
}

.error-sub-title-text-color {
    color: rgb(118, 118, 118);
}

.edit-campaign-settings-color {
    color: rgb(0, 115, 199);
}

.date-range-filter-margin-left {
    margin-left: 80px !important;
}

.search-margin-left {
    margin-left: -16px !important;
}

.export-margin-left {
    margin-left: 15px !important;
}


.product_targeting_categories_search_refine {
    position: absolute;
    top: 0%;
    left: -2px;
    background: rgba(235, 235, 237, 0.65);
    height: 100%;
    width: 100%;
    transform: translate(0%);
    transition: transform 200ms ease-in-out 0s, background-color 200ms ease-in-out 0s;
}

.product_targeting_categories_search_refine_child {
    margin-left: 10%;
    background: #fff;
    height: 100%;
}

.product_targeting_categories_search_refine_child_subchild {
    margin-left: 10%;
    height: 100%;
    background: #fff;
    filter: drop-shadow(rgba(0, 0, 0, 0.3) -1px 0px 4px);
    text-align: start;
}

.refine_header_parent {
    padding: 14px 22px;
}

.refine_header_subtitle {
    font-size: 0.75rem;
    margin: 0px;
}

.globe-image {
    height: 18px;
}

.pt_4px {
    padding-top: 4px !important;
}

.pt_6px {
    padding-top: 6px !important;
}

.user_profile_style {
    height: 36px;
    padding: 0px 10px;
    margin-top: 9px;
}

.profile_name {
    font-size: 10px !important;
    line-height: 0.8rem;
}

.refine_header {
    font-size: 13px;
    line-height: 1.465;
    font-weight: 700;
    font-family: "Amazon Ember", Helvetica, sans-serif;
    padding: 0px;
    color: inherit;
    margin: 0px;
}

.gBHflH {
    padding: 0px 22px;
    flex: 1 0 auto;
    display: flex;
    flex-flow: column;
    -webkit-box-pack: start;
    place-content: stretch flex-start;
    -webkit-box-align: stretch;
    align-items: stretch;
}

.fxhSbD {
    margin-top: 22px;
}

.dikvWV {
    font-size: 13px;
    line-height: 1.465;
    font-weight: normal;
    font-family: "Amazon Ember", Helvetica, sans-serif;
    padding: 0px;
    flex: 1 1 auto;
}

.Price_range_dash {
    text-align: center;
    width: 30%;

}

.active li {
    font-weight: bold !important;
}

.list-style-type-none {
    list-style-type: none !important;
    padding: 0px 0px !important;
}

.list-style-type-none:hover {
    background-color: rgb(242, 244, 246);
}

.list-style-type-none.active {
    background-color: rgb(230, 233, 237);
}

.react-datepicker {
    border: 0px !important;
}

.react-datepicker__header {
    border: 0px !important;
    background-color: transparent !important;
    padding: 10px 0px !important;
}

.react-datepicker__navigation--previous,
.react-datepicker__navigation--next {
    margin-top: 10px !important;
}

.apply-button {
    line-height: 1.465;
    padding: 5px 0px;
    white-space: nowrap;
    border-radius: 999px;
    cursor: pointer;
    text-align: center;
    vertical-align: middle;
    border-width: 1px;
    border-style: solid;
    user-select: auto;
    background: rgb(0, 115, 199) !important;
    border-color: transparent;
    box-shadow: rgba(35, 47, 63, 0.3) 0px 1px 1px !important;
    padding-inline: 14px;
}

.tooltip-z-index {
    z-index: 12323 !important;
}


.placemnet-table-inner {
    overflow-x: scroll;
    overflow-y: visible;
    /* width:500px;  */
    margin-left: 445px !important;
}

.profiles-list-container {
    background-color: rgb(255, 255, 255);
    width: 350px;
    height: initial;
    border-radius: 6px;
    position: relative;
    left: -200px !important;
    padding: 14px 18px !important;
}

.profiles-details-container {
    background: rgb(230, 233, 237);
}

.profile-detail-label-text-color {
    color: rgb(92, 104, 124);
}

.profile-detail-label-value-text {
    color: rgb(35, 47, 63) !important;
}

.custom-explore-text-color:hover {
    background-color: transparent !important;
}

.profile-list:hover {
    background-color: #e1e2e2 !important;
}

.profile-list:active {
    background-color: rgb(243, 243, 243) !important;
}

.custom_link {
    cursor: pointer !important;
}

.back-to-help-text {
    color: #007185;
}

.get-help-create-ad-group {
    /* height: calc(100vh - 76px) !important; */
    height: 100% !important;
    width: 400px;
    transition: right 0.3s ease-in-out 0s;
    position: fixed;
    z-index: 500;
    top: 76px;
    overflow: hidden;
    padding: 10px;
    right: 400px;
}

.get-help-create-ad-group-modal-content {
    height: calc(100vh - 75px) !important;
}

.explore-get-help-modal {
    position: relative;
    width: 330px !important;
    left: auto !important;
    float: right !important;
    top: 28px;
}

.portfolio-button {
    width: 295px;
}

.campaign-creation-portfolio-dropdown {
    min-width: 299.998px !important;
    margin-top: -31px !important;
    margin-left: -2px !important;
    padding: 7px 0px !important;
    max-height: 400px !important;
}

.leave-modal {
    width: 400px !important;
}

/* .sponsored-products-modal-body{
    height: calc(100vh - 120px) !important; 
    overflow-y: auto;
} */

.ad-groups-negative-keyword-targeting-accordion:after {
    margin-left: -63.5% !important;
}

.budget-settings-card-container {
    border: none;
    background: rgb(255, 255, 255);
    box-shadow: rgba(35, 47, 63, 0.3) 0px 0px 2px 0px, rgba(35, 47, 63, 0.15) 0px 1px 4px 0px;
    border-radius: 8px;
    width: 100%;
}

.budget-rule-campaign-budget-text {
    color: rgb(92, 104, 124);
}

.settings-card-inner-padding {
    padding: 26px !important;
}

.rule-name-width {
    width: 237px;
}

.budget-type-sub-text-color {
    color: rgb(73, 85, 102);
}

.budget-date-range-date-width {
    width: 285px !important;
}

.budget-increase {
    width: 120px !important;
}

.budget-rule-footer-container {
    margin-top: -120px !important;
}

.learn-more-text-color:hover {
    color: #CA5905 !important;
}

.badge-custom-bg {
    background: rgb(1, 132, 133) !important;
}

.table-input-field-width {
    width: 105px !important;
}


/* dot pulse loading css start */

.dot-pulse {
    position: relative;
    left: -9999px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: red;
    color: #F4F5F7;
    color: red;
    color: #FF610C;
    box-shadow: 9999px 0 0 -5px;
    animation: dot-pulse 1.5s infinite linear;
    animation-delay: 0.1s;
}

.dot-pulse::before,
.dot-pulse::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #9880ff;
    color: #232F3F;
    /* color:green; */
}

.dot-pulse::before {
    box-shadow: 9984px 0 0 -5px;
    animation: dot-pulse-before 1.5s infinite linear;
    animation-delay: 0s;
}

.dot-pulse::after {
    box-shadow: 10014px 0 0 -5px;
    animation: dot-pulse-after 1.5s infinite linear;
    animation-delay: 0.5s;
}

@keyframes dot-pulse-before {
    0% {
        box-shadow: 9984px 0 0 -5px;
    }

    30% {
        box-shadow: 9984px 0 0 2px;
    }

    60%,
    100% {
        box-shadow: 9984px 0 0 -5px;
    }
}

@keyframes dot-pulse {
    0% {
        box-shadow: 9999px 0 0 -5px;
    }

    30% {
        box-shadow: 9999px 0 0 2px;
    }

    60%,
    100% {
        box-shadow: 9999px 0 0 -5px;
    }
}

@keyframes dot-pulse-after {
    0% {
        box-shadow: 10014px 0 0 -5px;
    }

    30% {
        box-shadow: 10014px 0 0 2px;
    }

    60%,
    100% {
        box-shadow: 10014px 0 0 -5px;
    }
}

/* dot pulse loading css end  */

/* nagarjuna latest css start*/
.portfolio-modal-content {
    /* max-width: 80%;
    margin   : auto; */
    height: 240px !important;
    width: 628px !important;

}

.portfolio-label {
    display: inline;
    font-size: 13px;
    line-height: 1.465;
    font-family: "Amazon Ember", Helvetica, sans-serif;
    padding: 0px;
    font-weight: 700;
    color: rgb(35, 47, 63);
    margin-top: 8px;
}

.portfolio-modal-text {
    font-size: 13px;
    line-height: 1.465;
    font-weight: normal;
    font-family: "Amazon Ember", Helvetica, sans-serif;
    padding: 0px;
    color: inherit;
    margin: 0px;
}

.portfolio-title {
    font-size: 17px;
    line-height: 1.255;
    font-weight: normal;
    font-family: "Amazon Ember", Helvetica, sans-serif;
    padding: 0px;
    color: inherit;
    margin: 0px;
}

/* .modal-profolio-body {
    height    : calc(100vh - 520px);
    overflow-y: auto;
} */

.create-portfolio-btn {
    cursor: not-allowed;
    outline: none;
    opacity: 1;
    background: rgb(230, 233, 237) !important;
    border-color: transparent;
    pointer-events: all;
    box-shadow: none;
    color: rgb(92, 104, 124) !important;
    padding: 5px 10px !important;
    font-size: 13px;
}

.cancel-portfolio-btn {
    line-height: 1.465;
    font-weight: normal;
    font-family: "Amazon Ember", Helvetica, sans-serif;
    font-size: 13px;
    background: none;
    border: none;
    padding: 0px;
    color: rgb(0, 115, 199);
    text-decoration: underline;
}

.add-metric-info {
    width: 100%;
    height: auto;
    padding: 20px 18px;
    background-color: white;
    z-index: 1;
    box-sizing: border-box;
    overflow-y: auto;
}

.add-metric-list {
    margin-top: 10px !important;
    box-shadow: rgb(191, 191, 191) 0px 0px 2px 0px;
    border-radius: 2px;
    position: relative;
    background-color: white;
    -webkit-box-flex: 1;
    flex-grow: 1;
    min-width: 0px;
}

.add-metric-border {
    min-height: 1px;
    overflow: visible;
    box-sizing: border-box;
    cursor: pointer;
    text-align: center;
    font-size: 13px;
    position: relative;
    padding: 19px 0px;
    font-family: "Amazon Ember", Helvetica, Roboto, Arial, sans-serif;
    float: right !important;
    box-shadow: rgb(191, 191, 191) 0px 0px 2px 0px !important;
    background-color: white !important;
    color: rgb(17, 17, 17) !important;
    border-top: 1px solid white !important;
    border-bottom: 1px solid white !important;
    border-image: initial !important;
    border-left: none !important;
    border-right: none !important;
    /* / width: calc(20% - 8px) !important; / */
    /* width: calc(20% - 8px) !important; */
    float: right !important;
    margin-right: 0px !important;
}

.one {
    position: absolute;
    background-color: white;
    top: 100%;
    height: 10px;
    width: 100%;
    left: 0px !important;
    z-index: 1;
    box-shadow: rgb(191, 191, 191) 0px 0px 2px 0px;
}

.two {
    position: absolute;
    background-color: white;
    width: 100%;
    z-index: 1;
    height: 30px;
    top: calc(100% - 3px);
    box-shadow: none;
    left: 1px !important;
    border-right: 1px solid #dee2e6 !important;
}

.col_md_2 {
    width: calc(19% - -5px) !important;
}

.add_metrics-bg:hover {
    background-color: rgb(250, 250, 250) !important;
}

.col_padding {
    padding: 0px 0px !important;
}

.metric-list-card-width {
    width: calc(23% - -7px) !important;
}

.col_margin {
    margin: 0px 5px !important
}

.negative-keyword-body {
    height: calc(100vh - 250px);
    overflow-y: auto;
    width: 100%;
}

.model-negative-keyword-content {
    height: inherit;
}

.product-header {
    flex: 1 1 auto;
    display: flex;
    flex-flow: wrap;
    -webkit-box-pack: start;
    place-content: flex-start;
    -webkit-box-align: center;
    align-items: center;
    width: 100%;
    min-width: 0px;
}

.product-header a {
    flex: 1 1 auto;
    min-width: 0px;
    display: block;
    overflow: hidden;
    line-height: 1.5em;
    max-height: 3em;
    overflow-wrap: break-word;
    text-decoration: none;
    font-size: 13px;
    color: #007185 !important;
}

.add-varaiation-label {
    line-height: 1.465;
    font-weight: normal;
    font-family: "Amazon Ember", Helvetica, sans-serif;
    font-size: 13px;
    background: none;
    border: none;
    padding: 0px;
    color: rgb(0, 115, 199);
}

.amount-info {
    font-size: 0.6875rem;
    display: inline;
    color: rgb(177, 39, 4);
}

.stock-info {
    color: rgb(196, 85, 0);
    font-size: 0.6875rem;
    display: inline;
}

.product-details {
    color: rgb(118, 118, 118);
    font-size: 0.6875rem;
    display: inline;
}

.review-count {
    font-size: 10px;
    color: #007185;
    font-weight: 700;
}

/* nagarjuna latest css ends*/

.campaign-creation-footer {
    margin-top: 229px !important;
}

.portfolio-name-width {
    width: 237px !important;
}

.portfolio-inner-table {
    overflow-x: scroll;
    overflow-y: hides;
    margin-left: 455px !important;
}

.customise-columns-modal-content {
    /* height: calc(100vh - 250px) !important;
    overflow-y: auto !important;
    width:95% !important; */
    height: 453px !important;
    width: 621px !important;
}

.modal-header-border-bottom {
    border: 0px !important;
}

.coulmns-list-group-broder .list-group-item {
    border-radius: 0px !important;
    border: 0px !important;
}

.list-item input:hover {
    background-color: rgb(172, 209, 236) !important;
}

.customise-columnslist {
    height: 285px !important;
    overflow-y: auto;
}

.customize-left-dropdown li.active {
    box-shadow: rgb(0, 115, 199) 3px 0px 0px inset;
}

.budget-rule-header-bg {
    background-color: rgb(251, 251, 252) !important;
    box-shadow: rgba(17, 17, 17, 0.3) 0px 1px 2px 0px !important;
}

.amazon-button {
    height: 30px;
    width: 100%;
    object-fit: contain;
}

.dots-loader-position {
    position: relative;
    top: 50%;
    left: 50%;
}

.carousel-control-prev-icon {
    background-image: url('./../images/svg_icons/carousel-left-arrow.svg') !important;
    background-repeat: no-repeat !important;
    font-size: 20px !important;
}

.carousel-control-next-icon {
    background-image: url('./../images/svg_icons/carousel-right-arrow.svg') !important;
    background-repeat: no-repeat !important;
}

/* .chart_component{
    height: 200px;
    width: 100% ;
} */

/* dot loader spinner positions starts */
/* .loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); 
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999; 
  }
  
  
  .dots-loader-container {
    background-color: #fff; 
    border-radius: 6px;
    padding: 20px; 
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2); 
  }
  
  
  .dot-pulse {
    width: 16px;
    height: 16px;
    background-color: #007bff; 
    border-radius: 50%;
    animation: pulse 1s infinite;
  }
  
  @keyframes pulse {
    0%, 100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2); 
    }
  } */

/* dot loader spinner positions ends */


/* .chart_styles{
    width:1100px !important; 
    height: 200px !important;
     object-fit: contain !important;
  } */
/* responsive css starts */

.log-in-modal-border {
    --bs-modal-border-color: 0px !important;
}

.budget-bootom-border {
    border: 0px !important;
}


@media (max-width: 768px) {
    body {
        width: 100% !important;
    }

    .two {
        border-right: 1px solid #dee2e6 !important;
    }

    .customise-columns-modal-content {
        height: 353px !important;
        width: 421px !important;
    }

    .customise-columnslist {
        height: 155px !important;
        overflow-y: auto;
    }

    .modal-preview {
        width: 420px !important;
        height: 219px !important;
    }

    .MuiSvgIcon-root {
        width: 2.5em !important;
        height: 1.5em !important;
    }

    .metrics-cols-extra-large {
        width: 100% !important;
    }

    .inner-without-fixed-columns {
        overflow-x: scroll;
        overflow-y: visible;
    }

    .find-a-portfolio {
        width: 100% !important;
    }

    .custom-export-button,
    .custom-customise-columns,
    .date-range-column,
    .custom-filter-btn {
        width: 100% !important
    }

    .custom-create-btn {
        width: 104% !important
    }

    .portfolio-modal-content {
        /* height: 257px !important; */
        height: calc(100vh - 250px) !important;
        overflow-y: auto !important;
        ;
        width: 100% !important;
    }

    .negative-keyword-container-add-group-creation {
        max-width: 70rem !important;
        margin-left: auto !important;
        margin-right: auto !important;
    }

    .campaign-creation-form-container {
        min-width: 60rem !important;
        margin-left: 30px !important;
        margin-right: 30px !important;
    }

    .portfolio-settings-container {
        width: 100% !important;
    }

    .card-width {
        width: 894px !important;
    }

    .portfolio-name {
        width: 75%;
    }

    .portfolio-setting-card {
        width: 100% !important;
    }

    .products-card {
        height: 570px !important;
        width: 894px !important;
    }

    .ad-groups-negative-keyword-targeting-accordion:after {
        margin-left: -70.5% !important;
    }

    .campaign-creation-negative-keyword-targeting-accordion:after {
        margin-left: -72.5% !important;
    }

    .keyword-target-position {
        position: relative;
        left: 105% !important;
    }

    .campaign-creation-keyword-target-position {
        left: 96% !important;
    }

    .negative-keyword-body-ads_modal {
        width: 95% !important;
    }
}

@media (max-width:575.99px) {
    .card-left {
        margin-left: 0rem !important;
    }

    .user_profile_style {
        height: 36px;
        padding: 0px 5px;
        margin-top: 9px;
        word-wrap: break-word;
    }

    .top_menu {
        border-bottom: 3px solid #FF610C;
        height: 100px;
    }
}

@media (min-width:576px) and (max-width:768px) {
    .custom-export-button {
        width: 100% !important
    }
}

@media (min-width:769px) and (max-width:991px) {
    .col_md_2_add_metrics_cards_tab_view {
        width: 0% !important;
    }

    .col_md_6 {
        width: 49% !important;
    }

    .metrics-cols-extra-large {
        width: 48% !important;
    }

    .two {
        height: 15px;
        border-right: 1px solid #dee2e6 !important;
    }

    .inner-without-fixed-columns {
        overflow-x: scroll;
        overflow-y: visible;
    }

    .campaign-creation-negative-keyword-targeting-accordion:after {
        margin-left: -600px !important;
    }


    .negative-keyword-container-add-group-creation {
        max-width: 70rem !important;
        margin-left: auto !important;
        margin-right: auto !important;
    }

    .card-width {
        width: 894px !important;
    }

    .portfolio-setting-card {
        width: 894px !important;
    }

    .products-card {
        height: 570px !important;
        width: 894px !important;
    }

    .ad-groups-negative-keyword-targeting-accordion:after {
        margin-left: -70.5% !important;
    }

    .keyword-target-position {
        position: relative;
        left: 105% !important;
        margin-left: auto !important;
        margin-right: auto !important;
    }

    .campaign-creation-form-container {
        min-width: 59rem !important;
    }

    .campaign-creation-keyword-target-position {
        position: relative;
        left: 96% !important;
    }

}

@media (min-width:992px) and (max-width:1199px) {
    .col_lg_2 {
        width: 19.66666667% !important;
    }

    .metrics-cols-large {
        width: 29% !important;
    }

    .line-chart {
        height: 20vh;
    }

    .inner-without-fixed-columns {
        overflow-x: scroll;
        overflow-y: visible;
    }

    .campaign-creation-negative-keyword-targeting-accordion:after {
        margin-left: -600px !important;
    }

    .card-width {
        width: 894px !important;
    }

    .portfolio-setting-card {
        width: 894px !important;
    }

    .products-card {
        height: 570px !important;
        width: 894px !important;
    }

    .ad-groups-negative-keyword-targeting-accordion:after {
        margin-left: -70.5% !important;
    }

    .keyword-target-position {
        position: relative;
        left: 105% !important;
    }

    .campaign-creation-form-container {
        min-width: 59rem !important;
    }

    .campaign-creation-keyword-target-position {
        left: 96% !important;
    }
}

@media (min-width:1200px) and (max-width:1260px) {
    .inner-without-fixed-columns {
        overflow-x: scroll;
        overflow-y: visible;
    }
}

@media (min-width:1200px) {
    .col_xl_2 {
        width: 19.66666667% !important;
    }

    .metrics-cols-extra-large {
        width: 23.66666667% !important;
    }

    .line-chart {
        height: 400px !important;
    }
}

/* resposnvie css ends */

.line-chart {
    position: relative;
    margin: auto;
    height: 400px;
    width: 100%;
    padding: 30px 0px;
}

input.form-control+span:focus {
    border: 0px solid rgb(0, 97, 169) !important;
    outline: 0px solid rgb(0, 97, 169) !important;
    box-shadow: none !important;
}

.form-control:focus {
    box-shadow: 0 0 0 0.25rem transparent !important;
}

.form-select:focus {
    box-shadow: 0 0 0 0.25rem transparent !important;
}

.input-group:focus {
    border: 2px solid rgb(0, 97, 169) !important;
    border-radius: 7px !important;
}

input.form-control.profile-search:focus {
    outline: 0px solid rgb(0, 97, 169) !important;
    box-shadow: none !important;
}

.vendor-btn {
    border-radius: 15px !important;
    border: solid 1px #fc7742 !important;
    margin-left: 1rem;
    color: #fc7742;
    height: 21px;
    font-size: 13px;
    font-weight: 700;
    background-color: #F4F5F7;
}

.bid-adjusment_table-input {
    height: 20px !important;
}

.table_scroll {
    /* height:calc(100vh - 368px) !important; */
    /* height:calc(100vh - 212px) !important; */
    height: calc(100vh - 150px) !important;
    overflow-y: auto !important;
}

.products_scroll {
    height: 361px !important;
    overflow-y: auto !important;
    overflow-x: hidden !important;
    width: 102.5% !important;
}

.products_scroll_height {
    height: 420px !important;
}

.products_scroll_height_two {
    height: 415px !important;
}

.products_scroll_ai_height {
    height: 410px !important;
}

.products_scroll_ai_group_height {
    height: 420px !important;
}

.less-prodcuts {
    width: 101.5% !important;
}

.less-add-prodcuts {
    width: 102.5% !important;
}

.added-prodcuts_scroll {
    height: 460x !important;
    overflow-y: auto !important;
    overflow-x: hidden !important;
    width: 102% !important;
}

.budget_text-color {
    color: rgb(85, 85, 85) !important;
}

.addedkeyword-scroll {
    height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
}

.campaign-drop-down-input {
    height: 31px !important;
}

.custom-filter-dropdown-input {
    height: 35px !important;
}

.switch {
    position: relative;
    display: inline-block;
    width: 35px;
    height: 17px;
}

.switch input {
    display: none;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #9e9fa3;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 10px;
    width: 10px;
    left: 4px;
    bottom: 3.5px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

input:checked+.slider {
    background-color: rgb(0, 115, 199);
}

input:focus+.slider {
    box-shadow: 0 0 1px rgb(0, 115, 199);
}

input:checked+.slider:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
}

.slider.round {
    border-radius: 34px;
    /* width        : 75%; */
}

.slider.round:before {
    border-radius: 50%;
}

.slider-auto-approve.round {
    border-radius: 34px;
    /* width        : 75%; */
}

.slider-auto-approve.round:before {
    border-radius: 50%;
}

.toggle-check-mark-position {
    left: 10% !important;
}

.on {
    display: none;
}

.on {
    color: white;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 36%;
    font-size: 10px;
    font-family: Verdana, sans-serif;
}

.off {
    color: white;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 70%;
    font-size: 10px;
    font-family: Verdana, sans-serif;
}

input:checked+.slider .on {
    display: block;
}

input:checked+.slider .off {
    display: none;
}

input:checked+.slider:before {
    transform: translateX(17px);
}

.add-cursor-pointer-not-allowed {
    cursor: not-allowed !important;
}

.added-btn-status {
    background-color: rgb(118, 118, 118);
}

.prodcuts-gray-out-bg {
    background-color: rgb(206, 211, 220);
}

.ad-group-name-title:hover {
    color: red !important;
}

.prodcut-card-ads {
    height: auto !important;
}

.portfolio-settings-card {
    background-color: rgb(251, 251, 251) !important;
    border: 1px solid rgb(221, 221, 221) !important;
}

.edit-portfolio-camapign-btn {
    border-radius: 999px 0px 0px 999px !important;
}

.edit-portfolio-dots-btn {
    border-radius: 0px 999px 999px 0px !important;
}

.budget-message-container {
    margin: 14px 28px;
    background-color: rgb(251, 251, 251) !important;
    border: 1px solid rgb(221, 221, 221) !important;
    width: 600px;
    padding: 18px 22px;
    border-radius: 3px;
}

.wrong-color:hover {
    color: #0073C7 !important;
    font-weight: bold !important;
}

.dots-dropdown-menu {
    position: absolute !important;
    left: -130px !important;
}

.add-campaigns-modal {
    height: calc(100vh - 380px);
    overflow-y: auto;
    width: 100%;
}

.login-spinner-border {
    --bs-spinner-width: 1.3rem !important;
    --bs-spinner-height: 1.3rem !important;
}

.export-spinner {
    --bs-spinner-width: 1rem !important;
    --bs-spinner-height: 1rem !important;
}

.active-filter-modal-position {
    position: relative;
    left: 85% !important;
    top: 50px !important;
    width: 400px !important;
    height: 280px !important;
}

.portfolio-custom-scroll {
    height: 300px !important;
    overflow-y: auto !important;
}

.edit-settings:hover {
    text-decoration: underline !important;
}

.portfolio_margin_space {
    margin-top: -21px !important;
    border-top: 1px solid rgb(221, 221, 221) !important;
}

.placement_margin_space {
    margin-top: -1px !important;
    /* padding-bottom:30px !important; */
}

.port_margin_space {
    margin-top: -1px !important;
    height: 40px !important;
}

.custom-bid-input-height {
    height: 27px !important;
}

.budget-input-group {
    height: 33px !important;
}

.ai-campaigns-settings {
    background-color: #f6f6f6 !important;
}

.ai-continue-position {
    margin-top: 32px !important
}

.budget-input-group-ai-campaigns {
    height: 25px !important;
}

.product-card-image {
    max-width: 100% !important;
    max-height: 100% !important;
    object-fit: contain !important;
}

.ai-products-text-color {
    color: #37909f !important;
}

.ai-product-bg-color {
    background-color: red;
}

.ai-campaign-product-tab {
    background-color: aliceblue;
}

.product-ai-tab:hover {
    background-color: rgb(243, 243, 243);
}

.stepper-label {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
}

.MuiStepLabel-labelContainer .Mui-active {
    color: #1976d2 !important;
    font-weight: bold !important;
    font-size: 20px !important;
}

.delete-button-position {
    position: relative;
    left: 6%;
    top: -155px;
}

.ai-group-product-bg-color {
    background-color: red !important;
}

.table-budget-input-group {
    height: 26px !important;
}

.login-page,
.register-page,
.forgot-password-page {
    display: block !important;
}

.amazon-button-border {
    border: 0px !important;
}

.campaign-adding-modal {
    max-width: 80% !important;
}

.date-range-dropdown {
    width: 700px !important;
}

.add-negative-keywords-modal-width {
    max-width: 70% !important;
}

.ad-groups-targeting-modal {
    max-width: 65% !important;
}

.product-image {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain
}

.add-btn-status {
    padding: 3px 10px
}

.keyword-targeting-data-scroll {
    height: 330px;
    overflow-y: auto;
    overflow-x: hidden;
    width: 102.5%;
}

.mutlirange-slider {
    border: none;
    box-shadow: none;
    padding: 15px 10px;
    width: 80%;
}

.ai-campaign-stepper-scroll {
    height: calc(100vh - 100px);
    overflow-y: auto;
}

.sponsored-ads-accounts {
    height: 200px;
    overflow-y: auto;
}

.plus_btn {
    border-radius: 50%;
    padding: 8px 14px;
}

.plus-btn-above-position {
    position: relative;
    left: 7%;
    top: 55px;
}

.products-plus-btn {
    margin-top: -45px;
}

.products-card-step-container {
    height: auto;
    overflow-y: auto;
}

.keyword-targeting-data-scroll {
    height: 260px;
    overflow-y: auto;
}

.negative-keyword-data-scroll {
    height: 430px;
    overflow-y: auto;
}

.keyword-added-input-field {
    width: 85px !important;
}

.performance-budget-color {
    color: rgb(73, 85, 102);
}

.warning-icon-color {
    color: rgb(180, 95, 0) !important;
}

.draft-status {
    color: rgb(196, 85, 0);
}

.search-button {
    background-color: #ced3dc !important;
}

.text-capital {
    text-transform: capitalize !important;
}

.alternate-bid-modal {
    width: 85% !important;
}

.alternate-label-width {
    width: 90% !important;
}

.campaign-settings-scroll {
    height: 300px !important;
    overflow-y: auto !important;
}

.start-date-position {
    margin-top: -6px !important;
}

.camapign-creation-loader-position {
    position: relative;
    top: 300px;
}

.products-image {
    width: 40px;
    height: 40px;
}

.negative-keyword-spinner-position {
    position: absolute;
    left: 54%;
}

.portfolio-table-scroll {
    height: calc(100vh - 245px) !important;
    overflow-y: auto !important;
}

.ad-group-product-image {
    height: 40px;
    width: 40px;
}

.stock-capitalize {
    text-transform: capitalize !important;
}

.text-capitalize-first {
    text-transform: lowercase;
}

.text-capitalize-first::first-letter {
    text-transform: uppercase;
}

.custom-capitalize::first-letter {
    text-transform: uppercase !important;
}

.selected-metric-card {
    /* box-shadow: ; */
    box-shadow: red 0px 0px 0px 0px inset !important;
    border-top: 4px solid red !important;
    /* Replace with your desired color */
}

.disabled-metric {
    opacity: 0.5;
    /* Adjust the opacity or use other styling to indicate that the metric is disabled */
    pointer-events: not-allowed !important;
    /* Disable pointer events to prevent interaction with disabled metrics */
    /* Add any other styling for disabled metrics */
}

.react-datepicker__input-container input {
    width: 165px !important;
    cursor: pointer !important;
    height: 32px !important;
}

.react-datepicker {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.request-disabled-bg-color {
    background: rgb(133, 189, 228) !important;
    color: white !important;
    cursor: not-allowed !important;
}

.chart-background-blur {
    backdrop-filter: contrast(0.98) !important;
}

.ai-alternate-label-width {
    width: 125% !important;
}

/* .css-ascpo7-MuiStepLabel-root {
    display:block !important;
}

.MuiStepLabel-iconContainer{
    display:flex !important;
    justify-content: center !important;
}

.MuiStepConnector-line{
    position: relative;
    top:-10px ;
} */

.nav-underline .nav-link.active,
.nav-underline .show>.nav-link {
    background: white;
}

.w_28 {
    width: 28% !important;
}

.product-separation-line-position {
    bottom: 5px;
}

.portfolio-table-container-column {
    padding-bottom: 32px !important;
}

.custom-shadow {
    box-shadow: 1px 1px 3px #232F3F !important;
}

.eye-icon-position {
    position: absolute;
    right: 35px;
    top: 50%;
    transform: translateY(-50%);
}

.sales-color {
    border-top: 4px solid #ff5733 !important;
    position: relative;
    z-index: 122323 !important;
}

.spend-color {
    border-top: 4px solid #373782 !important;
}

.roas-color {
    border-top: 4px solid #fe4a4a !important;
}

.impressions-color {
    border-top: 4px solid #4949da !important;
}

.ctr-color {
    border-top: 4px solid #3e1bc2 !important;
}

.clicks-color {
    border-top: 4px solid #301934 !important;
}

.cpc-color {
    border-top: 4px solid #1d2383 !important;
}

.orders-color {
    border-top: 4px solid #53f453 !important;
}

.acos-color {
    border-top: 4px solid #ff1cff !important;
}

.placeholder-color {
    color: #606265 !important;
}

.secondary-placeholder-color {
    color: #707375 !important
}

.cross-icon i:before {
    font-size: 20px !important;
    font-weight: bold !important;
}

.custom-z-index {
    position: relative !important;
    z-index: 123121212 !important;
}

.custom-register-password {
    position: absolute;
    right: 35px;
    top: 41.5%;
    transform: translateY(-50%);
}

.custom-login-password {
    position: absolute;
    right: 35px;
    top: 50%;
    transform: translateY(-50%);
}

.campaign-settings-circle {
    width: 17px !important;
}

.form-control,
.form-select {
    font-size: 13px !important;
}

.Mui-focusVisible {
    background-color: transparent !important;
}

.custom-metric-select {
    background-color: rgb(217, 222, 228) !important;
}

.list-style-type-none {
    list-style-type: none !important;
}


/* .inner {
    overflow: auto !important;
    height: calc(100vh - 150px) !important;
}

.th_sticky {
    position: sticky !important;
    top: 0 !important;
} */
.border-bottom-2 {
    border-bottom: 2px solid #0073C7;
}

.text-secondary.custom-button-background.cursor-pointer {
    color: #000 !important;
}
